<template>
  <VSkeletonLoader
    v-if="data"
    :loading="loading"
    :type="skeletonTypes"
    data-test-value="card-team-user"
  >
    <h4
      class="tt-text-title-2"
      data-test-value="card-team-header"
    >
      {{ $t('user-teams-widget.title') }}
    </h4>
    <div
      class="text-body-2 tt-light-mono-46--text mb-5"
    >
      {{ $tc('user-teams-widget.pluralized.teams', teamsCount) }}
    </div>
    <template v-if="teamsCount">
      <div
        v-for="(team, index) in teams"
        :key="index"
        class="d-flex justify-space-between mb-4"
      >
        <div>
          <div
            class="tt-text-body-2 font-weight-bold"
          >
            <TTPrompt
              :line="ttPromptConfig.name.line"
              :text="team.name"
              :open-delay="ttPromptConfig.name.openDelay"
              data-test-value="card-team-name-team"
            />
          </div>
          <div
            class="tt-text-body-2"
          >
            <TTPrompt
              :line="ttPromptConfig.staffPosition.line"
              :text="team.staffPosition"
              :open-delay="ttPromptConfig.staffPosition.openDelay"
              data-test-value="card-team-position"
            />
          </div>
        </div>
        <TTAvatar
          class="ml-2"
          :text="getTeamAvatarText(team.name)"
        />
      </div>
    </template>
    <template v-else>
      <div
        class="tt-text-body-2"
      >
        {{ $t('user-teams-widget.empty') }}
      </div>
    </template>
  </VSkeletonLoader>
</template>

<script>
export default {
  name: 'UserTeams',

  props: {
    data: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  TTPromptConfig: {
    name: {
      line: 2,
      openDelay: 600,
    },
    staffPosition: {
      line: 2,
      openDelay: 600,
    },
  },

  data() {
    return {
      skeletonTypes: 'article',
    };
  },

  computed: {
    teams() {
      return this.data;
    },

    teamsCount() {
      return this.teams.length;
    },

    ttPromptConfig() {
      return this.$options.TTPromptConfig;
    },
  },

  methods: {
    getTeamAvatarText(name) {
      return name.substring(0, 2);
    },
  },
};
</script>
