import { Store } from 'vuex';
import {
  toggleSnack, hideSnackMutation, toggleLoading, toggleNavDrawer, setUserRoles, setChiefs, setTeams, setUserAvatarUrl,
} from '@/plugins/vuex/mutationTypes';
import { initProfile, hideSnack } from '@/plugins/vuex/actionTypes';
import modules from '@/plugins/vuex/modules';
import plugins from '@/plugins/vuex/plugins';

const strict = process.env.NODE_ENV === 'development';

// TODO: временный костыль
let timeOut = null;

function createStore() {
  const store = new Store({
    strict,
    plugins,
    modules,

    // TODO: refactor use modules
    state: {
      loading: false,
      navDrawer: true,
      snackbar: {
        value: false,
        message: '',
        color: '',
        timeout: 2000,
      },
      roles: [],
      chiefs: [],
      teams: [],
      userAvatarUrl: '',
    },

    actions: {
      [initProfile]({ commit }, payload) {
        const {
          roles,
          chiefs,
          teams,
        } = payload;

        commit(setUserRoles, roles);
        commit(setChiefs, chiefs);
        commit(setTeams, teams);
      },

      [hideSnack]({ commit, state }) {
        const { snackbar } = state;

        // TODO: временный костыль пока не переделаем TTNotify
        clearTimeout(timeOut);
        timeOut = window.setTimeout(() => {
          commit(hideSnackMutation);
        }, snackbar.timeout);
      },
    },

    mutations: {
      [toggleLoading](state, payload) {
        state.loading = payload;
      },

      [setUserRoles](state, payload) {
        state.roles = payload;
      },

      [toggleNavDrawer](state, payload) {
        state.navDrawer = payload;
      },

      [toggleSnack](state, payload) {
        const { snackbar } = state;
        snackbar.value = true;
        snackbar.message = payload.message || '';
        snackbar.color = payload.color || '';
      },

      [hideSnackMutation](state) {
        const { snackbar } = state;
        snackbar.value = false;
      },

      [setChiefs](state, payload) {
        state.chiefs = payload;
      },

      [setTeams](state, payload) {
        state.teams = payload;
      },

      [setUserAvatarUrl](state, payload) {
        state.userAvatarUrl = payload;
      },
    },

    getters: {},
  });

  return store;
}

export default createStore;
