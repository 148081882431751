
import Vue, { PropType, VNodeDirective } from 'vue';
import { TranslateResult } from 'vue-i18n';
import { i18n } from '@/plugins';

export default Vue.extend({
  name: 'IntroRectangle',
  props: {
    value: {
      type: Object as PropType<VNodeDirective>,
      required: true,
    },
    width: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    introStyle: '',
  }),
  computed: {
    title(): string {
      return this.value.value.title as string;
    },
    text(): string {
      return this.value.value.text as string;
    },
    closeText(): string {
      return this.value.value.closeText as string;
    },
    dataTestValue(): string {
      return this.value.value.dataTestValue || '' as string;
    },
    closeLabel(): TranslateResult {
      return i18n.t('intro.actions.ok');
    },
  },
  mounted() {
    this.$nextTick(() => {
      const { x } = (this.$refs.intro as Element).getBoundingClientRect();

      const left = x + 400 > window.innerWidth ? -400 : this.width;
      this.introStyle = `transform: translate(${left}px)`;
    });
  },
});
