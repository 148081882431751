<template>
  <VList
    class="ma-0"
  >
    <VListGroup
      v-for="competency in competencyList"
      :key="competency.cid"
      class="competency-list pl-0 ma-0"
      sub-group
    >
      <template #prependIcon>
        <VIcon
          color="tt-light-blue--text text--base"
          size="12"
        >
          fal fa-chevron-down
        </VIcon>
      </template>
      <template #activator>
        <VListItemTitle
          class="tt-text-body-1 pa-0 ma-0 d-flex justify-space-between text-wrap"
        >
          <TTPrompt
            :line="1"
            :text="competency.cname"
            :open-delay="600"
            data-test-value="card-competency-competency-name"
          />
          <slot name="progress" />
        </VListItemTitle>
      </template>
      <VListItem
        v-for="skill in competency.skills"
        :key="skill.id"
        class="skill-item pa-0 ma-0 justify-space-between"
        inactive
      >
        <div
          v-if="skill.done"
        >
          <VBadge
            :value="skill.badge"
            bordered
            color="tt-light-purple vibrant"
            icon="fas fa-bolt"
            offset-x="15"
            offset-y="15"
            overlap
          >
            <VIcon
              class="skill-icon tt-light-mono-4 mr-0"
              color="tt-light-green vibrant"
              left
              size="16"
              data-test-value="card-competency-skill-done"
            >
              fas fa-check
            </VIcon>
          </VBadge>
        </div>
        <VIcon
          v-else
          class="skill-icon tt-light-mono-4 mr-0"
          color="tt-light-red vibrant"
          left
          size="16"
          data-test-value="card-competency-skill-done"
        >
          far fa-ban
        </VIcon>
        <div
          class="skill-title d-flex justify-space-between ml-3 align-center"
        >
          <TTPrompt
            :line="1"
            :text="skill.name"
            :open-delay="600"
            data-test-value="card-competency-skill-name"
          />
          <slot
            name="rating"
            v-bind="{ skill }"
          />
        </div>
      </VListItem>
    </VListGroup>
  </VList>
</template>

<script>
export default {
  name: 'UserCompetencyList',

  props: {
    competencyList: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.competency-list {
  border-bottom: 1px solid map-get($tt-light-mono-4, 'base');

  &:last-child,
  &.v-list-group--active {
    border-bottom: none;
  }

  ::v-deep .v-list-item {
    padding: 0 12px;
    margin: 0 -12px;

    .v-list-item__icon {
      min-width: auto;
      margin-right: 10px;
      margin-left: 4px;
    }
  }
}

.skill-item {
  .skill-icon {
    padding: 10px;
    border-radius: 50%;
  }

  .skill-title {
    width: 100%;
    min-height: 56px;
    border-bottom: 1px solid map-get($tt-light-mono-4, 'base');
  }
}
</style>
