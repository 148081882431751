import { render, staticRenderFns } from "./TTAvatar.vue?vue&type=template&id=ae3d72f6&scoped=true&"
import script from "./TTAvatar.vue?vue&type=script&lang=ts&"
export * from "./TTAvatar.vue?vue&type=script&lang=ts&"
import style0 from "./TTAvatar.scss?vue&type=style&index=0&id=ae3d72f6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae3d72f6",
  null
  
)

export default component.exports