/* eslint-disable import/prefer-default-export */
import { accessToken } from '@/services/cookies';
import { getDomainForCookies } from '@/helpers/url';

const { VUE_APP_GATEKEEPER_ADDRESS } = process.env;

/*
 * Обязательный guard для выполнения перед всеми переходами
 */
function hasToken(to, from, next) {
  const token = accessToken.get();

  if (!token) {
    accessToken.remove({ domain: getDomainForCookies() });

    // IMPORTANT: редиректим в гейткипер с сылкой на текущую страницу
    const url = new URL(`/?back=${window.location.href}`, VUE_APP_GATEKEEPER_ADDRESS);
    window.location.replace(url.href);

    return next(false);
  }

  return next();
}

export { hasToken };
