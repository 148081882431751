/* eslint-disable import/prefer-default-export */
const { VUE_APP_GOGHA_API_ADDRESS: baseUrl = 'http://localhost:3000/' } = process.env;

function getImageUrl({
  uuid, type, name, size,
}) {
  let url = `${baseUrl}${type}${uuid ? `/${uuid}` : ''}/${name}`;

  if (size) url += `/${size}`;

  return url;
}

export { getImageUrl };
