let isInit = false;

const setup = (userData) => {
  const helpDeskEddySwitchedOn = process.env.VUE_APP_HELPDESKEDDY_ON === 'true';
  const helpDeskEddySrc = process.env.VUE_APP_HELPDESKEDDY_SCRIPT;

  if (!helpDeskEddySwitchedOn) {
    console.error('helpDeskEddy отключен в переменных окружения');
    return;
  }

  if (helpDeskEddySwitchedOn && !helpDeskEddySrc) {
    console.error('Не могу подключить helpDeskEddy. Не указан путь к файлу скрипта в переменных окружения.');
    return;
  }

  if (!isInit && helpDeskEddySwitchedOn) {
    const script = document.createElement('script');

    script.id = 'hde-chat-widget';
    script.src = helpDeskEddySrc;
    script.dataset.host = 'servicedesk.potok.io';
    script.dataset.lang = 'ru';
    script.dataset.position = 'bottom-right';
    script.defer = true;

    script.dataset.userName = userData.userName;
    script.dataset.userEmail = userData.userEmail;

    document.body.appendChild(script);
  }

  isInit = true;
};

export default setup;
