import { getImageUrl } from '@/helpers/gogha/getImageUrl';

export const avatarText = (firstName, lastName) => {
  if (firstName && lastName) {
    return `${firstName[0]}${lastName[0]}`;
  }

  return null;
};

export const avatarUrl = (uuid) => {
  const type = 'user';
  const name = 'avatar';
  return getImageUrl({ uuid, type, name });
};

export const fio = (lastName, firstName, middleName) => [lastName, firstName, middleName].filter((n) => n).join(' ');

export default {
  avatarText,
  avatarUrl,
  fio,
};
