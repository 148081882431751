import Vue from 'vue';
import VueRouter from 'vue-router';
import createRouter from '@/plugins/vue-router/router';
import routes from '@/plugins/vue-router/routes';

Vue.use(VueRouter);

const { BASE_URL: baseUrl } = process.env;

const router = createRouter({
  baseUrl,
  routes,
});

export * from '@/plugins/vue-router/routeNames';
export default router;
