<template>
  <VAlert
    :data-test="dataTest"
    :data-test-label="dataTestLabel"
    :data-test-value="dataTestValue"
    :value="value"
    :dismissible="dismissible"
    :color="color"
    :width="width"
    :height="height"
    :max-height="maxHeight"
    :max-width="maxWidth"
    :min-height="minHeight"
    :min-width="minWidth"
    elevation="1"
  >
    <template
      v-if="type"
      #prepend
    >
      <slot name="prepend">
        <VIcon
          :color="iconType.color"
          class="mr-2"
        >
          {{ iconType.icon }}
        </VIcon>
      </slot>
    </template>

    <template
      v-if="!dismissible"
      #append
    >
      <slot name="append" />
    </template>

    <template
      v-if="dismissible"
      #close="{toggle}"
    >
      <VIcon @click="toggle">
        $close
      </VIcon>
    </template>
    <slot />
  </VAlert>
</template>

<script>
/* eslint-disable vue/require-default-prop */

// IMPORTANT: этот компонент не используется? Используется .ts версия? Протестировать .ts версию!
const icons = {
  success: {
    icon: 'fa fa-check-circle',
    color: 'success',
  },
  info: {
    icon: 'fa fa-info-circle',
    color: 'info',
  },
  warning: {
    icon: 'fa fa-exclamation-circle',
    color: 'warning',
  },
  error: {
    icon: 'fa fa-exclamation-circle',
    color: 'error',
  },
};
export default {
  name: 'TTAlert',
  props: {
    dataTest: {
      type: String,
      default: 'tt-alert',
    },
    dataTestLabel: {
      type: String,
      default: '',
    },
    dataTestValue: {
      type: String,
      default: '',
    },
    value: Boolean,
    dismissible: Boolean,
    maxWidth: [Number, String],
    minWidth: [Number, String],
    maxHeight: [Number, String],
    minHeight: [Number, String],
    height: [Number, String],
    width: [Number, String],
    color: {
      type: String,
      default: 'white',
    },
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconType() {
      return icons[this.type];
    },
  },
};
</script>

<style scoped lang="scss">

.elevation-1 {
  box-shadow: 0 2px 24px rgba(11, 18, 24, 0.08) !important;
}

.v-icon.v-icon {
  font-size: 19px;
}
</style>
