import Vue from 'vue';
import BottleJS from 'bottlejs';

import userUUIDGet from '@/helpers/userUUIDGet';

import * as featuresApi from '@/services/api/features';
import FeatureFlag from '@/plugins/feature-flag';

import TopMind from '@/plugins/top-mind';

const bottle = new BottleJS();

// FeatureFlag
let flags = [];

bottle.defer(async () => {
  const { features } = await featuresApi.featuresGet();
  flags = features;
});

bottle.resolve('flags');

bottle.factory('ff', () => new FeatureFlag(flags));

// TopMind
(() => {
  const userId = userUUIDGet();
  if (userId) {
    const { VUE_APP_TOPMIND_SCRIPT, VUE_APP_TOPMIND_CLIENT_ID, VUE_APP_TOPMIND_TRACKER_ID } = process.env;
    const topMind = new TopMind(
      VUE_APP_TOPMIND_SCRIPT,
      VUE_APP_TOPMIND_CLIENT_ID,
      VUE_APP_TOPMIND_TRACKER_ID,
      [{ initApiCall: false }],
    );

    topMind.setUserId(userId);

    bottle.factory('tmt', () => topMind);
  }
})();

const install = (vue) => {
  Object.defineProperty(vue.prototype, '$di', {
    get() {
      return bottle.container;
    },
  });
};

Vue.use(install);

export default bottle;
