<template>
  <TTModuleMenu
    :items-tree="menuItems"
    hide-sign-out
  >
    <template #item-append="item">
      <div
        v-intro:[getIntroName(item)]="getIntroValue(item)"
        class="menu-intro"
      />
    </template>
  </TTModuleMenu>
</template>

<script>
import { mapState } from 'vuex';

const INTRO_ORGSTRUCTURE_NAME = 'orgstructure';

export default {
  name: 'AppAsideLeft',
  inject: ['Names'],
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      orgstructure: {
        badge: !localStorage.getItem(INTRO_ORGSTRUCTURE_NAME),
      },
    };
  },

  computed: {
    ...mapState(['chiefs', 'teams']),

    orgstructureFeatureIsActive() {
      return this.$di.ff.get('orgstructure');
    },

    menuItems() {
      const items = [[
        {
          id: 1,
          title: this.$t('profile'),
          to: { name: this.Names.R_APP_PROFILE },
          icon: 'fal fa-user',
          dataTest: 'tt-btn',
          testValue: 'profile',
        },
      ]];

      if (this.isShowTeam) {
        items[0].push(
          {
            id: 2,
            title: this.$t('my_team'),
            to: { name: this.Names.R_APP_TEAM },
            icon: 'fal fa-users',
            dataTest: 'tt-btn',
            testValue: 'team',
          },
        );
      }

      if (this.orgstructureFeatureIsActive && this.teams.length) {
        items[0].push(
          {
            id: 3,
            title: this.$t(INTRO_ORGSTRUCTURE_NAME),
            to: { name: this.Names.R_APP_ORGSTRUCTURE_TREE },
            icon: 'fal fa-sitemap',
            dataTest: 'tt-btn',
            testValue: INTRO_ORGSTRUCTURE_NAME,
            badge: this.orgstructure.badge,
            intro: {
              ...this.$t('intro.orgstructure'),
              cancelCallback: this.closeOrgstructureIntro,
            },
          },
        );
      }

      return items;
    },
    isShowTeam() {
      return this.chiefs.length !== 0 || this.teams.length !== 0;
    },
  },
  methods: {
    getIntroValue(item) {
      return item.intro ?? undefined;
    },
    getIntroName(item) {
      return item.intro ? item.intro.alias : undefined;
    },
    closeOrgstructureIntro() {
      this.orgstructure.badge = false;
    },
  },
};
</script>

<style scoped lang="scss">
.custom-item {
  min-height: 0;
  &::before {
    opacity: 0 !important;
  }
  &:focus {
    &::before {
      opacity: 0 !important;
    }
  }
}
.menu-intro {
  width:40px;
  height: 80px;
}
</style>
