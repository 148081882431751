import Vue from 'vue';
import bottle from '@/plugins/di';
import {
  i18n, router, store, vuetify, usedesk, helpdeskeddy,
} from '@/plugins';
import App from '@/App.vue';
import '@/directives';

import * as profileApi from '@/services/api/profile';
import { init } from '@/helpers/store';
import { fio } from '@/helpers/users';

// импорт для динамических виджетов
import AppNav from '@/components/app/AppNav.vue';
import AppAsideLeft from '@/components/app/AppAsideLeft.vue';
import WidgetWrapper from '@/components/widgets/WidgetWrapper.vue';
import UserInfo from '@/components/widgets/UserInfo.vue';
import UserTeams from '@/components/widgets/UserTeams.vue';
import UserCompetency from '@/components/widgets/UserCompetency/UserCompetency.vue';

// глобальное подключение для динамических виджетов
Vue.component(AppNav.name, AppNav);
Vue.component(AppAsideLeft.name, AppAsideLeft);
Vue.component(WidgetWrapper.name, WidgetWrapper);
Vue.component(UserInfo.name, UserInfo);
Vue.component(UserTeams.name, UserTeams);
Vue.component(UserCompetency.name, UserCompetency);

Vue.config.productionTip = false;

// TODO: подумать над инициализацией iife
(async () => {
  try {
    const profile = await profileApi.profileGet();
    const chiefs = await profileApi.profileGetChiefs();
    const { teamsWithPeople } = await profileApi.profileGetTeams();
    init(store.dispatch, {
      roles: profile.roles,
      chiefs,
      teams: teamsWithPeople,
    });
    const { flags } = bottle.container.ff;
    if (flags.help_desk_eddy_enabled) {
      helpdeskeddy({
        userName: fio(profile.name.last, profile.name.first, profile.name.middle),
        userEmail: profile.email,
      });
    } else {
      usedesk(profile);
    }
  } catch (err) {
    // no-error
  } finally {
    new Vue({
      name: 'TalentTech',
      i18n,
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  }
})();
