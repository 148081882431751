/* eslint-disable import/prefer-default-export */
import * as methods from '@/services/api/constants';
import transport from './transport';

/**
 * Гайд по именованию ресурсов
 *
 * @see: https://burning-heart.atlassian.net/wiki/spaces/front/pages/1375109156/JavaScript
 */

/*
 * Сводная информация по профилю пользователя
 *
 * @example: profileGet();
 */
export const profileGet = ({ data = {}, ...options } = {}) => transport({
  url: 'profile',
  method: methods.HTTP_GET,
  data,
  ...options,
});

/*
 * Руководители пользователя
 */
export const profileGetChiefs = ({ data = {}, ...options } = {}) => transport({
  url: 'profile/chiefs',
  method: methods.HTTP_GET,
  data,
  ...options,
});

/*
 * Команды пользователя
 */
export const profileGetTeams = ({ data = {}, ...options } = {}) => transport({
  url: 'profile/teams_with_people',
  method: methods.HTTP_GET,
  data,
  ...options,
});

/*
 * Список компаний пользователя
 *
 * @example: profileCompaniesGet();
 */
export const profileCompaniesGet = ({ data = {}, ...options } = {}) => transport({
  url: 'profile/companies',
  method: methods.HTTP_GET,
  data,
  ...options,
});

/*
 * Возвращает список названий приложений, к которым у компании или аккаунта
 * есть лицензии
 *
 * @example: profileAppsGet();
 */
export const profileAppsGet = ({ data = {}, ...options } = {}) => transport({
  url: 'profile/apps',
  method: methods.HTTP_GET,
  data,
  ...options,
});

/*
 * Отправляет новый аватар из цифрового профиля
 *
 * @example: profileAvatarPost();
 * @example: profileAvatarPost({ data: formData });
 */
export const profileAvatarPost = ({ data = {}, ...options } = {}) => transport({
  url: 'profile/avatar',
  method: methods.HTTP_POST,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  data,
  ...options,
});

/*
 * Получает ID для ссылки приглашения гостя
 *
 */
export const companyInvitationGet = ({ params = {}, ...options } = {}) => transport({
  url: 'company/invitation',
  method: methods.HTTP_GET,
  params,
  ...options,
});

/*
 * Get a list of all teams of a company
 *
 * @example: companyGetTeams();
 */
export const companyGetTeams = ({ data = {}, ...options } = {}) => transport({
  url: 'company/teams',
  method: methods.HTTP_GET,
  data,
  ...options,
});

export const adminLicensesGet = ({ data = {}, ...options } = {}) => transport({
  url: 'admin/licences',
  method: methods.HTTP_GET,
  ...options,
  data,
});

export const getScopes = ({ data = {}, ...options } = {}) => transport({
  url: '/scopes',
  method: methods.HTTP_GET,
  data,
  ...options,
});

export const getProfileScheme = ({ uuid, ...options }: { uuid: string }) => transport({
  url: `/v2/profile/${uuid}`,
  method: methods.HTTP_GET,
  ...options,
});

export const getProfileDS = ({ uuid, ds, ...options }: { uuid: string, ds: any }) => transport({
  url: `/v2/profile/${uuid}/ds/${ds}`,
  method: methods.HTTP_GET,
  ...options,
});

export const getProfile = ({ id, ...options }: { id: string }) => transport({
  url: `/dp/${id}`,
  method: methods.HTTP_GET,
  ...options,
});

export const editAboutProfile = ({ data, ...options }: { data: any }) => transport({
  url: '/dp/about',
  method: methods.HTTP_PUT,
  ...options,
  data,
});

export const getUserColleagues = ({ userId, ...options }: { userId: string }) => transport({
  url: `dp/${userId}/colleagues`,
  method: methods.HTTP_GET,
  ...options,
});

export const editPreviousJobs = ({ data, ...options }: { data: any }) => transport({
  url: '/dp/previous_jobs',
  method: methods.HTTP_PUT,
  ...options,
  data,
});

export const editContactsProfile = ({ data, ...options }: { data: any }) => transport({
  url: '/dp/contacts',
  method: methods.HTTP_PUT,
  ...options,
  data,
});

export const deleteAvatar = ({ ...options }) => transport({
  url: '/profile/avatar',
  method: methods.HTTP_DELETE,
  ...options,
});
