import { VNodeDirective, VueConstructor } from 'vue';

type IntroType = 'drop' | 'circle' | 'rectangle';

type IntroPosition = 'base' | 'right';

interface IntroDirective extends VNodeDirective {
  // имя группы подсказок
  arg?: string;
  // данные подсказки
  value?: {
    // порядковый номер для сортировки
    index?: number;
    // тип: капля или круг
    type: IntroType;
    // размер
    size?: '180' | '120';
    title: string;
    text: string;
    position?: IntroPosition
    solid?: boolean;
    cancelCallback?: Function
  };
}

let startTimerId: ReturnType<typeof setTimeout>;

class Intro {
  private store: Array<{ el: HTMLElement; binding: IntroDirective }> = [];

  // текущее название раздела подсказок
  private currentName = '';

  private step = 0;

  // компоненты для подсказок
  readonly components = {} as Partial<Record<IntroType, VueConstructor>>;

  constructor(options: {
    // компоненты для подсказок
    components: Partial<Record<IntroType, VueConstructor>>;
  }) {
    this.components = options.components;
  }

  // список разделов подсказок
  get names() {
    const names = this.store.map(({ binding }) => binding.arg || '').filter(Boolean);
    return Array.from(new Set(names)).sort((a, b) => {
      if (a === 'help') return 1;
      if (b === 'help') return -1;
      return 0;
    });
  }

  // подсказки текущего раздела
  private get currentList() {
    return this.store
      .filter(({ binding: { arg, value } }) => arg === this.currentName && value)
      .sort(({ binding: a }, { binding: b }) => (a.value?.index || 0) - (b.value?.index || 0));
  }

  private helpHandler() {
    this.names.forEach((name) => {
      if (name !== 'help') localStorage.removeItem(name);
    });
    this.show();
  }

  public bind(el: HTMLElement, binding: IntroDirective) {
    if (!binding.value) return;
    this.store.push({ el, binding });
    if (binding.modifiers?.show) el.addEventListener('click', () => this.helpHandler());
    if (!this.currentName) {
      clearTimeout(startTimerId);
      startTimerId = setTimeout(() => this.show(), 2000);
    }
  }

  public unbind(el: HTMLElement, binding: IntroDirective) {
    if (binding.arg === this.currentName) this.currentName = '';
    this.store = this.store.filter((storeElement) => storeElement.el !== el);
    const elemList = Array.from(document.getElementsByClassName('intro'));
    elemList.forEach((elem) => elem.remove());
  }

  // отобразить подсказку
  show(name?: string) {
    if (this.currentName) return;
    const notViewed = this.names.filter((item) => !localStorage.getItem(item));
    if (name || notViewed[0]) {
      this.currentName = name || notViewed[0];
      this.step = 0;
      this.next();
    }
  }

  // остановить показ текущего списка подсказок и перейти к следующему
  cancel(binding: IntroDirective) {
    localStorage.setItem(this.currentName, 'viewed');
    this.currentName = '';
    this.step = 0;
    this.show();

    if (binding.value?.cancelCallback) binding.value.cancelCallback();
  }

  // показать следующую подсказку
  private next() {
    this.render();
    this.step += 1;
  }

  private render() {
    const { el, binding: value } = this.currentList[this.step];
    const intro = document.createElement('div');
    const app = document.getElementsByClassName('v-application')[0];
    if (!app) return;
    app.appendChild(intro);
    const componentType = value.value?.type;
    const componentPosition = value.value?.position;

    if (!componentType) return;
    const Component = this.components[componentType];
    if (!Component) return;
    const { width, height } = el.getBoundingClientRect();
    const drop = new Component({
      propsData: {
        value,
        step: this.step,
        length: this.currentList.length,
        width,
        height,
      },
    });
    drop.$mount(intro);

    const element = drop.$el as HTMLElement;

    const setPosition = () => {
      const { x, y } = el.getBoundingClientRect();
      const top = `${(y / 2) + (height / 2) + window.scrollY}px`;

      switch (componentPosition) {
        case 'right':
          element.style.top = top;
          element.style.left = `${x + window.scrollX}px`;
          break;
        default:
          element.style.top = `${y + window.scrollY}px`;
          element.style.left = `${x + window.scrollX}px`;
      }
    };

    setPosition();
    element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    window.addEventListener('scroll', setPosition);
    window.addEventListener('resize', setPosition);

    const removeHandler = () => {
      window.removeEventListener('resize', setPosition);
      window.removeEventListener('scroll', setPosition);
      drop.$destroy();
      drop.$el.remove();
      intro.remove();
    };

    drop.$on('next', () => { removeHandler(); this.next(); });
    drop.$on('close', () => { removeHandler(); this.cancel(value); });
  }
}

export { Intro, IntroDirective };
export default Intro;
