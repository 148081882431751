/* eslint-disable import/prefer-default-export */
import accessToken from '@/services/cookies/accessToken';
import companyId from '@/services/cookies/companyId';
import { getDomainForCookies } from '@/helpers/url';

const { VUE_APP_ARCHIVIST_ADDRESS, VUE_APP_GATEKEEPER_ADDRESS } = process.env;

/*
 * Обязательный interceptor для обработки глобальных и локальный ошибок
 *
 * @see: https://burning-heart.atlassian.net/wiki/spaces/FND/pages/1983938561
 */
async function onError(error) {
  const HTTP_FORBIDDEN = 403;
  const currentModuleUrl = new URL(window.location.href);
  const archivistModuleUrl = new URL(VUE_APP_ARCHIVIST_ADDRESS);
  const gatekeeperModuleUrl = new URL(VUE_APP_GATEKEEPER_ADDRESS);
  const flushAuth = () => {
    accessToken.remove({ domain: getDomainForCookies() });
    companyId.remove({ domain: getDomainForCookies() });
  };
  // HACK: после того как fatal и нормальный json ответ приедет во все сервисы убрать fallback
  const fallback = error.response?.data === null;
  const isGlobalError = error.isAxiosError === true
    && [HTTP_FORBIDDEN].includes(error.response?.status)
    && (fallback || Boolean(error.response?.data?.error?.fatal) === true);
  const isArchivistModule = currentModuleUrl.origin === archivistModuleUrl.origin;
  const hasRetry = Boolean(currentModuleUrl.searchParams.get('retry')) === true;
  // TODO: whitelist для урлов и back урлов
  const redirectTo = (targetUrl) => window.location.replace(targetUrl);

  if (isGlobalError) {
    if (isArchivistModule) {
      // Глобальная ошибка в профиле
      flushAuth();

      redirectTo(gatekeeperModuleUrl.href);
    } else if (hasRetry) {
      // Глобальная ошибка в модуле и есть флаг retry
      redirectTo(archivistModuleUrl.href);
    } else {
      // Глобальная ошибка в модуле и нет флага retry
      flushAuth();

      const backUrl = new URL('/?retry=true', currentModuleUrl);

      const url = new URL(`/?back=${backUrl.href}`, gatekeeperModuleUrl);

      redirectTo(url.href);
    }
  }

  return Promise.reject(error);
}

export { onError };
