import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ru from 'vuetify/es5/locale/ru';
import TTLightTheme from '@uikit/themes/tt.light.theme';
import TTDarkTheme from '@uikit/themes/tt.dark.theme';
import icons from '@uikit/icons';

Vue.use(Vuetify);

const options = {
  theme: {
    default: 'light',
    themes: {
      light: TTLightTheme,
      dark: TTDarkTheme,
    },
    options: {
      // Note: IE does not support CSS Custom Properties
      customProperties: false,
    },
  },

  /*
  * Можно подмешать свои иконки через icons :{...icons, foo:'fa fa-bars'}
  */
  icons: {
    iconfont: icons.iconfont,
    values: {
      ...icons.values,
    },
  },

  lang: {
    locales: { ru },
    current: 'ru',
  },
};

export default new Vuetify(options);
