<template>
  <div
    class="tt-input tt-text-field tt-select tt-autocomplete"
    :class="classesList"
    :data-test="dataTest"
    :data-test-label="dataTestLabel"
    :data-test-value="dataTestValue"
  >
    <div
      v-if="!!label"
      class="label"
    >
      <label>{{ label }}</label>
    </div>
    <VAutocomplete
      ref="VAutocomplete"
      :class="[ rotateClass ]"
      :label="''"
      v-bind="{...$attrs, ...$props}"
      :data-test="`${dataTest}--input`"
      :data-test-label="dataTestLabel"
      :data-test-value="dataTestValue"
      @input="input"
      @blur="blur"
      @focus="focus"
      v-on="$listeners"
    >
      <template #append>
        <slot name="append">
          <div
            v-if="error"
            class="d-flex align-center justify-center fill-height"
          >
            <VIcon
              color="error"
            >
              fa fa-exclamation-circle
            </VIcon>
          </div>
        </slot>
      </template>

      <template #prepend>
        <slot name="prepend" />
      </template>

      <template #prepend-inner>
        <slot
          v-if="multiple"
          name="tt-clearable"
          v-bind="{value}"
        >
          <TTChip
            v-show="selectedSize"
            class="tt-chip-clearable"
            close
            close-icon="fal fa-times"
            @click:close="clearSelected"
          >
            {{ selectedSize }}
          </TTChip>
        </slot>

        <slot name="prepend-inner" />
      </template>

      <template #item="props">
        <slot
          name="item"
          v-bind="props"
        >
          {{ verificationPropItemText(props.item) }}
        </slot>
      </template>

      <template
        v-if="$scopedSlots.selection"
        #selection="props"
      >
        <slot
          name="selection"
          v-bind="props"
        />
      </template>

      <template #append-item>
        <slot name="append-item" />
      </template>
    </VAutocomplete>
  </div>
</template>

<script>
export default {
  name: 'TTAutocomplete',
  props: {
    value: {
      type: undefined,
      default: undefined,
    },
    attach: {
      type: [Boolean, String, Object],
      default: false,
    },
    color: {
      type: String,
      default: 'tt-light-mono-100',
    },
    hideDetails: {
      type: [Boolean, String],
      default: 'auto',
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    itemText: {
      type: [String, Array, Function],
      default: 'text',
    },
    multiple: {
      type: Boolean,
      default: false,
    },

    // custom props
    noRotate: {
      type: Boolean,
      default: false,
    },
    xLarge: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    xSmall: {
      type: Boolean,
      default: false,
    },

    returnObject: {
      type: Boolean,
      default: false,
    },

    dataTest: {
      type: String,
      default: 'tt-autocomplete',
    },
    dataTestLabel: {
      type: String,
      default: '',
    },
    dataTestValue: {
      type: String,
      default: '',
    },
  },
  computed: {
    selectedSize() {
      return this.value?.length;
    },
    selectedItemsRef() {
      return this.multiple
        ? this.$refs.VAutocomplete.$refs['input-slot'].querySelector('.v-select__selections')
        : this.$refs.VAutocomplete.$refs.input;
    },
    classesList() {
      return {
        'v-size--x-large': this.xLarge,
        'v-size--large': this.large,
        'v-size--small': this.small,
        'v-size--x-small': this.xSmall,
        'v-size--default': !this.xLarge && !this.large && !this.xSmall && !this.small,
        'with-checkboxes': this.multiple,
      };
    },
    contentClass() {
      let cls = 'tt-select';
      if (this.multiple) cls += ' with-checkboxes';
      return cls;
    },
    menuPropsComputed() {
      return {
        'offset-y': true,
        'content-class': this.contentClass,
        ...this.menuProps,
      };
    },
    rotateClass() {
      return this.noRotate ? 'no-rotate' : '';
    },
  },
  methods: {
    verificationPropItemText(item) {
      if (typeof this.itemText === 'function') {
        return this.itemText(item);
      }

      if (typeof item === 'string') {
        return item;
      }

      return item[this.itemText];
    },
    async blur() {
      if (this.value) {
        await this.$nextTick();
        this.selectedItemsRef.scroll(0, 0);
      }
    },
    async input() {
      await this.$nextTick();
      this.$refs.VAutocomplete.$refs.input.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    },
    async focus() {
      await this.$nextTick();
      this.$refs.VAutocomplete.$refs.input.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    },
    clearSelected() {
      this.$emit('input', null);
    },
  },
};
</script>

<style lang="scss">
@import "../TTInput/TTInput";
@import "../TTTextField/TTTextField";
@import "../TTTSelect/TTSelect";
@import "TTAutocomplete";

</style>
