export default class FeatureFlag {
  constructor(features = []) {
    this.features = features;
  }

  get flags() {
    return this.features.reduce((acc, val) => {
      const {
        enabled,
        name,
      } = val;

      acc[name] = enabled;

      return acc;
    }, {});
  }

  get(value, raw) {
    if (raw) {
      return this.features.find((feature) => feature.name === value);
    }

    return this.flags[value];
  }
}
