/* eslint-disable import/prefer-default-export */
import { redirectUrl, maxAvailableWindowWidthSize } from '@/constants/index';
import isMobile from '@/helpers/isMobile';
import isTablet from '@/helpers/isTablet';

/*
 * Обязательный guard для выполнения перед всеми переходами
 */
function isMobileOrTablet(to, from, next) {
  if (isMobile() || (isTablet() && window.innerWidth > maxAvailableWindowWidthSize)) {
    const url = new URL(redirectUrl.MOBILE_AND_TABLE_REDIRECT_LINK);
    window.location.replace(url.href);

    return next(false);
  }

  return next();
}

export { isMobileOrTablet };
