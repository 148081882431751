<template>
  <VCol
    v-if="!dsError"
    class="wrapper mb-5"
    cols="12"
  >
    <slot />
  </VCol>
</template>

<script>
export default {
  name: 'WidgetWrapper',

  props: {
    dsError: {
      type: Error,
      default: () => null,
    },
  },
};
</script>

<style>
.wrapper{
  border-radius: 8px;
  box-shadow: 0 2px 24px rgba(11, 18, 24, 0.08);
}
</style>
