<template>
  <div
    class="rating d-flex flex-shrink-0"
  >
    <div
      class="rating-items d-flex align-center"
    >
      <div
        v-for="item in requiredLevel"
        :key="item"
        :class="{'filled' : item <= currentLevel}"
        class="rating-item ml-1"
      />
    </div>
    <div
      class="tt-text-body-2 tt-light-mono-46--text ml-3"
    >
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserCompetencyRating',

  props: {
    title: {
      type: String,
      default: '',
    },
    currentLevel: {
      type: Number,
      default: 0,
    },
    requiredLevel: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.rating {
  width: 170px;

  .rating-items {
    width: 60px;
  }

  .rating-item {
    width: 8px;
    height: 8px;
    border: 1px solid map-get($tt-light-blue, 'vibrant');
    border-radius: 50%;

    &:first-child {
       margin-left: 0;
    }

    &.filled {
       background: map-get($tt-light-blue, 'vibrant');
     }
  }
}
</style>
