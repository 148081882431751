import crypto from 'crypto';
import userUUIDGet from '@/helpers/userUUIDGet';

let isInit = false;

const setup = (profile, additional_fields = []) => {
  const useDeskSwitchedOn = process.env.VUE_APP_USEDESK_ENABLE === 'true';
  const useDeskSrc = process.env.VUE_APP_USEDESK_SCRIPT_URL;

  if (!useDeskSwitchedOn) {
    console.error('useDesk отключен в переменных окружения');
    return;
  }

  if (useDeskSwitchedOn && !useDeskSrc) {
    console.error('Не могу подключить useDesk. Не указан путь к файлу скрипта в переменных окружения.');
    return;
  }

  if (!isInit && useDeskSwitchedOn) {
    const script = document.createElement('script');

    const uuid = userUUIDGet();

    const fio = Object.values(profile.name)
      .join(' ');

    const currentApplicationHost = window.location.hostname.split('.')
      .slice(1)
      .join('.')
      || 'localhost';

    const token = crypto.createHash('sha256')
      .update(`${uuid}_${currentApplicationHost}`)
      .digest('hex');

    const identity = {
      name: fio,
      email: profile.email || '',
      phone: profile.phoneNumber || '',
      token,
      additional_fields,
    };

    // eslint-disable-next-line no-underscore-dangle
    window.__widgetInitCallback = (widget) => {
      widget.identify(identity);
    };

    script.src = useDeskSrc;
    document.body.appendChild(script);

    isInit = true;
  }
};

export default setup;
