import Vue from 'vue';

import { VRow, VCol, VContainer } from 'vuetify/lib';

import TTAppBar from '@uikit/components/TTAppBar.vue';
import TTAlert from '@uikit/components/TTAlert.vue';
import TTBtn from '@uikit/components/TTBtn/TTBtn.vue';
import TTDataTable from '@uikit/components/TTDataTable/TTDataTable.vue';
import TTNavigationDrawer from '@uikit/components/TTNavigationDrawer.vue';
import TTSnackbar from '@uikit/components/TTSnackbar.vue';
import TTToolBar from '@uikit/components/TTToolbar.vue';
import TTToolBarTitle from '@uikit/components/TTToolbarTitle.vue';
import TTTextField from '@uikit/components/TTTextField/TTTextField.vue';
import TTTextarea from '@uikit/components/TTTextarea/TTTextarea.vue';
import TTAutoComplete from '@uikit/components/TTAutocomplete/TTAutocomplete.vue';
import TTSelect from '@uikit/components/TTTSelect/TTSelect.vue';
import TTModuleMenu from '@uikit/components/TTModuleMenu/TTModuleMenu.vue';
import TTPrompt from '@uikit/components/TTPrompt/TTPrompt.vue';
import TTChip from '@uikit/components/TTChip/TTChip.vue';
import TTAvatar from '@uikit/components/TTAvatar/TTAvatar.vue';
// @ts-ignore
import TTCheckbox from '@uikit/components/TTCheckbox/TTCheckbox';
// @ts-ignore
import TTNotify from '@uikit/components/TTNotify/TTNotify';
import TTTooltip from '@uikit/components/TTTooltip/TTTooltip.vue';
import TTView from '@views/TTView.vue';

Vue.component('VRow', VRow);
Vue.component('VCol', VCol);
Vue.component('VContainer', VContainer);

Vue.component(TTAppBar.name, TTAppBar);
Vue.component(TTAlert.name, TTAlert);
Vue.component(TTBtn.name, TTBtn);
Vue.component(TTDataTable.name, TTDataTable);
Vue.component(TTNavigationDrawer.name, TTNavigationDrawer);
Vue.component(TTSnackbar.name, TTSnackbar);
Vue.component(TTToolBar.name, TTToolBar);
Vue.component(TTToolBarTitle.name, TTToolBarTitle);
Vue.component(TTView.name, TTView);
Vue.component(TTTextField.name, TTTextField);
Vue.component(TTTextarea.name, TTTextarea);
Vue.component(TTAutoComplete.name, TTAutoComplete);
Vue.component(TTSelect.name, TTSelect);
Vue.component(TTModuleMenu.name, TTModuleMenu);
Vue.component('TTCheckbox', TTCheckbox);
Vue.component('TTNotify', TTNotify);
Vue.component('TTChip', TTChip);
Vue.component(TTTooltip.name, TTTooltip);
Vue.component(TTPrompt.name, TTPrompt);
Vue.component('TTAvatar', TTAvatar);
