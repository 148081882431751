/* eslint-disable import/prefer-default-export */
import * as Names from '@/plugins/vue-router/routeNames';
import bottle from '@/plugins/di';

async function hasFeatureFlag(to, from, next) {
  const {
    feature = null,
  } = to.meta;

  if (!feature) {
    return next();
  }

  const { flags } = bottle.container.ff;

  if (flags[feature] === false) {
    return next({ name: Names.R_ERROR_404 });
  }

  return next();
}

export { hasFeatureFlag };
