
import Vue, { PropType, VNodeDirective } from 'vue';

import IntroSteppers from './IntroSteppers.vue';
import IntroActions from './IntroActions.vue';

export default Vue.extend({
  name: 'IntroDrop',
  components: {
    IntroActions,
    IntroSteppers,
  },
  props: {
    value: {
      type: Object as PropType<VNodeDirective>,
      required: true,
    },
    step: {
      type: Number,
      default: 0,
    },
    length: {
      type: Number,
      default: 0,
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    introStyle: '',
    introClass: 'intro--right intro--bottom intro--120',
    isMirrored: false,
  }),
  computed: {
    title(): string {
      return this.value.value.title as string;
    },
    text(): string {
      return this.value.value.text as string;
    },
    size(): '120' | '180' {
      return this.value.value.size === '180' ? '180' : '120';
    },
    closeText(): string {
      return this.value.value.closeText as string;
    },
    dataTestValue(): string {
      return this.value.value.dataTestValue || '' as string;
    },
  },
  mounted() {
    this.$nextTick(() => {
      const { x } = (this.$refs.intro as Element).getBoundingClientRect();

      const left = x + 430 > window.innerWidth ? -430 : this.width;
      this.introStyle = `transform: translate(${left}px, ${this.height}px)`;

      const introClass = ['intro--drop', 'intro--bottom', `intro--${this.size}`];
      this.isMirrored = x + 430 > window.innerWidth;
      introClass.push(this.isMirrored ? 'intro--left' : 'intro--right');
      this.introClass = introClass.join(' ');
    });
  },
});
