export const LevelEnum = {
  LEVEL_INVALID: 'LEVEL_INVALID',
  LEVEL_NOVICE: 'LEVEL_NOVICE',
  LEVEL_BASIC: 'LEVEL_BASIC',
  LEVEL_PROFICIENT: 'LEVEL_PROFICIENT',
  LEVEL_ADVANCED: 'LEVEL_ADVANCED',
  LEVEL_EXPERT: 'LEVEL_EXPERT',
};

export const LevelNameMap = {
  [LevelEnum.LEVEL_INVALID]: 'Не определён',
  [LevelEnum.LEVEL_NOVICE]: 'Нулевой',
  [LevelEnum.LEVEL_BASIC]: 'Базовый',
  [LevelEnum.LEVEL_PROFICIENT]: 'Средний',
  [LevelEnum.LEVEL_ADVANCED]: 'Продвинутый',
  [LevelEnum.LEVEL_EXPERT]: 'Экспертный',
};

export const LevelValueMap = {
  [LevelEnum.LEVEL_INVALID]: 0,
  [LevelEnum.LEVEL_NOVICE]: 1,
  [LevelEnum.LEVEL_BASIC]: 2,
  [LevelEnum.LEVEL_PROFICIENT]: 3,
  [LevelEnum.LEVEL_ADVANCED]: 4,
  [LevelEnum.LEVEL_EXPERT]: 5,
};
