<template>
  <div
    class="services d-flex flex-wrap"
  >
    <VChip
      v-for="(service, i) in services"
      :key="i"
      class="tt-light-mono-64--text"
      color="tt-light-mono-4"
    >
      {{ service }}
    </VChip>
  </div>
</template>

<script>
export default {
  name: 'UserCompetencyServices',

  props: {
    services: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.services {
  column-gap: 12px;
  row-gap: 12px;
}
</style>
