
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';

import { i18n } from '@/plugins';

export default Vue.extend({
  name: 'IntroActions',
  props: {
    last: Boolean,
    mirrored: Boolean,
    closeCustomText: {
      type: String,
      default: null,
    },
  },
  computed: {
    closeLabel(): TranslateResult {
      return this.closeCustomText || i18n.t('intro.actions.close');
    },
    nextLabel(): TranslateResult {
      return i18n.t('intro.actions.next');
    },
  },
});
