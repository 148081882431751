import Router from 'vue-router';
import store from '@/plugins/vuex';
import { hasToken } from '@/plugins/vue-router/guards/hasToken';
import { hasCompany } from '@/plugins/vue-router/guards/hasCompany';
import { isMobileOrTablet } from '@/plugins/vue-router/guards/isMobile';
import { hasFeatureFlag } from '@/plugins/vue-router/guards/hasFeatureFlag';
import { middlewarePipeline } from '@/plugins/vue-router/guards/middleware/middlewarePipeline';

function createRouter({ routes, baseUrl, ...options }) {
  const router = new Router({
    mode: 'history',
    baseUrl,
    // Note: отображается во вкладке маршрутов в devtools@^5.0.1
    routes,
    ...options,
  });

  router.beforeEach(hasToken);
  router.beforeEach(hasCompany);
  router.beforeEach(isMobileOrTablet);
  router.beforeEach(hasFeatureFlag);
  router.beforeEach((to, from, next) => middlewarePipeline({ to, next, store }));

  return router;
}

export default createRouter;
