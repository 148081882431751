import store from '@/plugins/vuex';
import { initProfile, hideSnack } from '@/plugins/vuex/actionTypes';
import {
  toggleSnack, setUserRoles, setUserAvatarUrl,
} from '@/plugins/vuex/mutationTypes';

export const hideSnackHandler = (action) => action(hideSnack, false, { root: true });

export const snackSuccess = (commit, message) => {
  commit(toggleSnack, {
    color: 'success',
    message,
  }, { root: true });

  hideSnackHandler(store.dispatch);
};

export const snackError = (commit, message) => {
  commit(toggleSnack, {
    color: 'error',
    message,
  }, { root: true });

  hideSnackHandler(store.dispatch);
};

export const setRoles = (commit, roles) => commit(setUserRoles, roles, { root: true });

export const init = (action, payload) => action(initProfile, payload, { root: true });

export const setUserAvatar = (commit, payload) => commit(setUserAvatarUrl, payload, { root: true });
