export const R_APP = 'app';
export const R_APP_TEAM = 'app.team';
export const R_APP_ME = 'app.me';
export const R_APP_PROFILE = 'app.profile';
export const R_APP_ORGSTRUCTURE = 'app.orgstructure';
export const R_APP_ORGSTRUCTURE_TREE = 'app.orgstructure.tree';
export const R_ADMIN = 'app.admin';
export const R_ADMIN_USERS = 'app.admin.users';
export const R_ADMIN_LICENSES = 'app.admin.licenses';
export const R_ADMIN_APPLICATIONS = 'app.admin.applications';
export const R_ADMIN_APPLICATIONS_CREATE = 'app.admin.applications.create';
export const R_ADMIN_APPLICATIONS_EDIT = 'app.admin.applications.edit';
export const R_ADMIN_USERS_CREATE = 'app.admin.users.create';
export const R_ADMIN_USERS_EDIT = 'app.admin.users.edit';
export const R_ADMIN_ORGSTRUCTURE_TEAM = 'app.admin.orgstructure.team';
export const R_ERROR_401 = '401';
export const R_ERROR_403 = '403';
export const R_ERROR_404 = '404';
export const R_ERROR_500 = '500';

export const R_PROFILE = 'profile';

export const R_ADMIN_ORGSTRUCTURE_IMPORT_INDEX = 'app.admin.orgstructure.import.index';
export const R_ADMIN_ORGSTRUCTURE_IMPORT_UPLOAD = 'app.admin.orgstructure.import.upload';
export const R_ADMIN_ORGSTRUCTURE_IMPORT_PROCESSING = 'app.admin.orgstructure.import.processing';
