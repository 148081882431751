<template>
  <VSkeletonLoader
    v-if="data"
    :loading="loading"
    :type="skeletonTypes"
    :class="{'d-flex' : !loading,'flex-column' : bigAvatar, 'avatar-custom' : bigAvatar}"
    data-test-value="card-user"
  >
    <TTAvatar
      :class="avatarClasses"
      :src="avatarUrl"
      :text="avatarText"
      :x-large="!bigAvatar"
    />
    <div>
      <h2
        class="tt-text-title-1 mb-1"
        data-test-value="card-name-user"
      >
        {{ fio }}
      </h2>
      <div
        class="tt-text-body-1"
      >
        <TTPrompt
          :line="ttPromptConfig.staffPosition.line"
          :text="user.staffPosition"
          :open-delay="ttPromptConfig.staffPosition.openDelay"
          data-test-value="card-position-user"
        />
      </div>
      <div
        class="tt-text-body-1 tt-light-mono-46--text"
      >
        <TTPrompt
          :line="ttPromptConfig.staffPosition.line"
          :text="user.team"
          :open-delay="ttPromptConfig.staffPosition.openDelay"
          data-test-value="card-team-user"
        />
      </div>
      <div
        class="mt-1"
      >
        <span
          v-if="user.phone"
          class="tt-text-body-2 pr-2"
          data-test-value="card-phone-user"
        >
          {{ user.phone }}
        </span>
        <span class="tt-text-body-2">
          <a
            :href="'mailto:' + user.email"
            class="text-decoration-none"
            data-test-value="card-email-user"
          >
            {{ user.email }}
          </a>
        </span>
      </div>
    </div>
  </VSkeletonLoader>
</template>

<script>
import { avatarText, avatarUrl, fio } from '@/helpers/users';

export default {
  name: 'UserInfo',

  props: {
    data: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    bigAvatar: {
      type: Boolean,
      default: false,
    },
  },

  TTPromptConfig: {
    staffPosition: {
      line: 2,
      openDelay: 600,
    },
    team: {
      line: 2,
      openDelay: 600,
    },
  },

  computed: {
    user() {
      return this.data[0] || {};
    },

    fio() {
      const {
        user: {
          lastName,
          firstName,
          middleName,
        },
      } = this;

      return fio(lastName, firstName, middleName);
    },

    avatarText() {
      const {
        user: {
          firstName,
          lastName,
        },
      } = this;

      return avatarText(firstName, lastName);
    },

    avatarUrl() {
      const {
        user: { id },
      } = this;

      return avatarUrl(id);
    },

    avatarClasses() {
      const {
        bigAvatar,
      } = this;

      return {
        'avatar-custom--size': bigAvatar,
        'mb-4': bigAvatar,
        'mr-4': !bigAvatar,
      };
    },

    skeletonTypes() {
      return this.bigAvatar ? 'avatar, list-item-three-line' : 'list-item-avatar-three-line';
    },

    ttPromptConfig() {
      return this.$options.TTPromptConfig;
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar-custom {
  &--size,
  ::v-deep .v-skeleton-loader__avatar {
    width: 160px;
    height: 160px;
  }
}
</style>
