<template>
  <VSkeletonLoader
    v-if="data"
    :loading="loading"
    :type="skeletonTypes"
    data-test-value="card-competency"
  >
    <h4
      class="tt-text-title-2"
      data-test-value="card-competency-header"
    >
      {{ $t('user-competency-widget.title') }}
    </h4>
    <div
      class="text-body-2 tt-light-mono-46--text mb-2"
    >
      {{ $t('user-competency-widget.date', {date : assessmentDate}) }}
    </div>
    <UserCompetencyList
      :competency-list="competencyList"
      data-test-value="card-competency-list"
    >
      <template #progress>
        <UserCompetencyProgress
          :value="60"
          data-test-value="card-competency-progress"
        />
      </template>
      <template #rating="{ skill }">
        <UserCompetencyRating
          :current-level="levelValue(skill.currentLevel)"
          :required-level="levelValue(skill.requiredLevel)"
          :title="levelName(skill.currentLevel)"
          data-test-value="card-competency-rating"
        />
      </template>
    </UserCompetencyList>
    <template v-if="isShowServices">
      <h4
        class="tt-text-title-2 mb-5"
        data-test-value="card-competency-services-header"
      >
        {{ $t('user-competency-widget.services.title') }}
      </h4>
      <UserCompetencyServices
        :services="services"
        data-test-value="card-competency-services"
      />
    </template>
  </VSkeletonLoader>
</template>

<script>
import formatDate from '@/helpers/formatDate';
import { LevelNameMap, LevelValueMap } from '@/components/widgets/UserCompetency/constants';
import UserCompetencyList from '@/components/widgets/UserCompetency/ui/UserCompetencyList.vue';
import UserCompetencyProgress from '@/components/widgets/UserCompetency/ui/UserCompetencyProgress.vue';
import UserCompetencyRating from '@/components/widgets/UserCompetency/ui/UserCompetencyRating.vue';
import UserCompetencyServices from '@/components/widgets/UserCompetency/ui/UserCompetencyServices.vue';

export default {
  name: 'UserCompetency',
  components: {
    UserCompetencyList,
    UserCompetencyProgress,
    UserCompetencyRating,
    UserCompetencyServices,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isShowServices: false,
      services: [
        'Проведение анализа конкурентов',
        'Подготовка отчетов по результатам аналитики',
        'Проведение исследования рынка',
        'Проведение опросов',
      ],
      skeletonTypes: 'article, list-item-three-line, article',
    };
  },

  computed: {
    assessmentDate() {
      return formatDate(new Date(), { format: 'DD MMMM YYYY' });
    },

    competencyList() {
      const competencyList = this.data;
      return competencyList.reduce((acc, val) => {
        const {
          cid,
          cname,
          name,
          requiredLevel,
          currentLevel,
          done,
          badge,
        } = val;

        const skill = {
          id: `${cid}_${name}`,
          name,
          requiredLevel,
          currentLevel,
          done: done === 'true', // в ручке значение String
          // TODO: дать нормальное название, когда бэк определится со схемой
          badge: !!badge,
        };

        if (acc[cid]) {
          acc[cid].skills.push(skill);
        } else {
          acc[cid] = {
            cid,
            cname,
            skills: [skill],
          };
        }

        return acc;
      }, {});
    },
  },

  methods: {
    levelName(level) {
      return LevelNameMap[level];
    },

    levelValue(level) {
      return LevelValueMap[level];
    },
  },
};
</script>
