
import Vue from 'vue';

export default Vue.extend({
  name: 'IntroSteppers',
  props: {
    step: {
      type: Number,
      default: 0,
    },
    length: {
      type: Number,
      default: 0,
    },
    mirrored: {
      type: Boolean,
      default: false,
    },
  },
});
