<template>
  <AppNavbar
    :current-app="currentApp"
    :items="myApps"
    @click-module-btn="sendAnalyticExternalLink($event.href, $event.text)"
  >
    <template #company>
      <TTBtn
        v-if="isAdmin"
        v-intro:admin-intro="adminIntro"
        text
        :depressed="false"
        color="tt-light-mono-100"
        data-test-value="nav-admin-link"
        data-test-label="nav-admin-link"
        height="46"
        class="tt-text-caption font-weight-regular px-0 mr-10 custom-item"
        @click="sendAnalyticExternalLink(profileAdminLink, $t('apps.profile_admin'))"
      >
        <VIcon class="mr-2">
          fal fa-cog
        </VIcon>
        {{ $t("apps.profile_admin") }}
      </TTBtn>
      <AppProfileMenu
        v-intro:dashboard-intro="intro"
        :user-name="fio"
        :user-avatar="avatarUrl"
        :user-initials="avatarText"
        :current-company="myCompany"
        :companies="companies"
        :actions="computedActions"
        show-header
        @input="sendAnalyticOpenProfileMenu"
        @my-data="handleMyData"
        @my-team="handleMyTeam"
        @my-profile="handleMyProfile"
        @orgstructure="handleOrgstructure"
        @sign-out="handleSignOut"
        @select-company="selectCompany($event)"
      />
    </template>
  </AppNavbar>
</template>

<script>
import { getImageUrl } from '@/helpers/gogha/getImageUrl';
import * as profileApi from '@/services/api/profile';
import AppNavbar from '@widgets/AppNavbar.vue';
import AppProfileMenu from '@widgets/AppProfileMenu.vue';
import { accessToken, companyId } from '@/services/cookies';
import { getDomainForCookies } from '@/helpers/url';
import { snackError } from '@/helpers/store';
import userUUIDGet from '@/helpers/userUUIDGet';
import { mapState } from 'vuex';
import { LEARNING_APPS_LIST } from '@/constants';

const {
  VUE_APP_GATEKEEPER_ADDRESS,
  VUE_APP_PROFILEADMIN_ADDRESS,
  VUE_APP_ARCHIVIST_ADDRESS,
} = process.env;

export default {
  name: 'AppNav',
  components: { AppNavbar, AppProfileMenu },
  inject: ['Names'],
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentApp: VUE_APP_ARCHIVIST_ADDRESS,
      profileAdminLink: VUE_APP_PROFILEADMIN_ADDRESS,
      actions: [
        {
          id: 'my-profile',
          icon: 'fal fa-user',
          text: this.$t('profile'),
          testValue: 'my-profile',
        },
        {
          id: 'my-team',
          icon: 'fal fa-users',
          text: this.$t('my_team'),
          testValue: 'my-team',
        },
        {
          id: 'orgstructure',
          icon: 'fal fa-sitemap',
          text: this.$t('orgstructure'),
          testValue: 'orgstructure',
        },
        {
          id: 'sign-out',
          icon: 'fal fa-sign-out',
          text: this.$t('sign-out'),
          color: 'red',
        },
      ],
      apps: [],
      companies: [],
      profile: {
        name: {
          first: '',
          middle: '',
          last: '',
        },
      },
      company: {
        id: '',
        name: 'Компания',
      },
      avatarUrl: '',
    };
  },
  computed: {
    ...mapState(['roles', 'chiefs', 'teams', 'userAvatarUrl']),

    orgstructureFeatureIsActive() {
      return this.$di.ff.get('orgstructure');
    },

    isHideTeam() {
      return this.chiefs.length === 0 && this.teams.length === 0;
    },

    computedActions() {
      let actions = [...this.actions];
      if (this.isHideTeam) {
        actions = actions.filter(({ id }) => id !== 'my-team');
      }

      if (!(this.orgstructureFeatureIsActive && this.teams.length)) {
        actions = actions.filter(({ id }) => id !== 'orgstructure');
      }

      return actions;
    },

    myApps() {
      const licenses = this.apps.map((i) => i.appName);

      const dict = {
        [process.env.VUE_APP_GOALS_ADDRESS]: ['pms'],
        [process.env.VUE_APP_LEARNINGBOX_ADDRESS]: LEARNING_APPS_LIST,
        [process.env.VUE_APP_ENGAGEMENTADMIN_ADDRESS]: ['engagement'],
        [process.env.VUE_APP_ONBOARDING_ADDRESS]: ['snami'],
      };

      const apps = [
        {
          text: this.$t('apps.front_goals'),
          href: process.env.VUE_APP_GOALS_ADDRESS,
          testValue: 'goals',
          event: true,
        },
        {
          text: this.$t('apps.nk_front'),
          href: process.env.VUE_APP_LEARNINGBOX_ADDRESS,
          testValue: 'learning',
          event: true,
        },
        {
          text: this.$t('apps.front_engagement_admin'),
          href: process.env.VUE_APP_ENGAGEMENTADMIN_ADDRESS,
          testValue: 'engagement',
          event: true,
        },
        {
          text: this.$t('apps.front_onboarding'),
          href: process.env.VUE_APP_ONBOARDING_ADDRESS,
          testValue: 'snami',
          event: true,
        },
      ];

      return apps.filter((app) => {
        const license = dict[app.href];
        return !!(license && license.some((appLicense) => licenses.includes(appLicense)));
      });
    },

    isAdmin() {
      return !!this.roles.find((e) => e.role === 'admin');
    },

    myCompany() {
      const imageMeta = {
        uuid: this.company.id,
        type: 'company',
        name: 'logo',
      };
      const logo = getImageUrl(imageMeta) || '';
      return {
        logo,
        name: this.company.name,
        id: this.company.id,
        subtitle: this.$t('my_company'),
      };
    },
    myProfile() {
      const imageMeta = {
        uuid: this.company.id,
        type: 'company',
        name: 'logo',
      };
      const logo = getImageUrl(imageMeta) || '';
      return {
        logo,
        name: this.company.name,
        id: this.company.id,
        subtitle: this.$t('my_company'),
      };
    },
    fio() {
      const {
        profile: { name },
      } = this;
      return [name.last, name.first, name.middle].filter((n) => n).join(' ');
    },
    avatarText() {
      const {
        profile: { name },
      } = this;
      if (name?.first && name?.last) {
        return `${name.first[0]}${name.last[0]}`;
      }
      return null;
    },
    intro() {
      if (this.$route.name !== this.Names.R_ADMIN_ORGSTRUCTURE) {
        return this.$t('intro.help')[0];
      }
      return null;
    },
    adminIntro() {
      if (this.isAdmin) {
        return this.$t('intro.help')[1];
      }
      return null;
    },
  },
  watch: {
    userAvatarUrl() {
      this.setAvatarUrl();
    },
    profile() {
      this.setAvatarUrl();
    },
  },
  async created() {
    await this.fetch();
  },
  methods: {
    setAvatarUrl() {
      const uuid = userUUIDGet();
      const type = 'user';
      const name = 'avatar';
      const url = getImageUrl({ uuid, type, name });
      const time = new Date().getTime();
      this.avatarUrl = `${url}?t=${time}`;
    },
    sendAnalyticOpenProfileMenu(opened) {
      if (opened) {
        this.$di.tmt.sendEvent('send', 'click-profile-menu', null, null, null, null);
      }
    },
    sendAnalyticExternalLink(url, label) {
      this.$di.tmt.sendEvent('send', 'click-nav-bar-module', null, null, null, { url, label, type: 'externalLink' });
      window.location.href = url;
    },
    sendAnalyticInternalLink(url, label) {
      this.$di.tmt.sendEvent('send', 'click-profile-menu-item', null, null, null, { url, label, type: 'internalLink' });
    },
    handleMyData() {
      this.sendAnalyticInternalLink(this.Names.R_APP_ME, this.$t('my_data'));
      this.$router.push({ name: this.Names.R_APP_ME });
    },
    handleMyProfile() {
      this.sendAnalyticInternalLink(this.Names.R_APP_PROFILE, this.$t('profile'));
      this.$router.push({ name: this.Names.R_APP_PROFILE });
    },
    handleMyTeam() {
      this.sendAnalyticInternalLink(this.Names.R_APP_TEAM, this.$t('my_team'));
      this.$router.push({ name: this.Names.R_APP_TEAM });
    },
    handleOrgstructure() {
      this.sendAnalyticInternalLink(this.Names.R_APP_TEAM, this.$t('orgstructure'));
      this.$router.push({ name: this.Names.R_APP_ORGSTRUCTURE_TREE });
    },
    selectCompany(company) {
      if (company.id === this.myCompany.id) {
        return;
      }
      this.$di.tmt.sendEvent(
        'send',
        'click-profile-menu-item',
        null,
        null,
        null,
        { type: 'changeCompany', old: this.myCompany.id, new: company.id },
      );
      companyId.set(company.id, { domain: getDomainForCookies() });
      window.location.replace('/');
    },
    handleSignOut() {
      this.$di.tmt.sendEvent('send', 'click-profile-menu-item', null, null, null, { type: 'singOut' });
      accessToken.remove({ domain: getDomainForCookies() });
      companyId.remove({ domain: getDomainForCookies() });
      // IMPORTANT: редиректим в гейткипер с сылкой на текущую страницу
      const url = new URL(`/?back=${window.location.href}`, VUE_APP_GATEKEEPER_ADDRESS);
      window.location.replace(url.href);
    },
    async fetch() {
      try {
        const { companies } = await profileApi.profileCompaniesGet();
        this.companies = companies;
        this.company = companies.find((i) => i.id === companyId.get());
      } catch (err) {
        snackError(this.$store.commit, this.$t('error'));
      }
      try {
        const apps = await profileApi.profileAppsGet();
        this.apps = apps || [];
      } catch (err) {
        snackError(this.$store.commit, this.$t('error'));
      }
      try {
        this.profile = await profileApi.profileGet();
      } catch (err) {
        snackError(this.$store.commit, this.$t('error'));
      }
    },
  },
};
</script>

<style scoped lang="scss">
.custom-item {

  min-width: auto !important;

  &:hover{
    &::after {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: map-get($tt-light-mono-100, 'base');
      content: '';
    }
  }

  &::before {
    opacity: 0 !important;
  }

  &:focus {
    &::before {
      opacity: 0 !important;
    }
  }
}
</style>
