<template>
  <div
    class="user-competency-progress d-flex flex-shrink-0 ml-2 align-center"
  >
    <VProgressLinear
      :value="value"
      color="tt-light-green vibrant"
      class="mr-3"
      rounded
    />
    {{ value }}%
  </div>
</template>

<script>
export default {
  name: 'UserCompetencyProgress',

  props: {
    value: {
      type: Number,
      default: 50,
    },
  },
};
</script>

<style lang="scss" scoped>
.user-competency-progress {
  width: 165px;
}
</style>
