
import Vue, { PropType, VNodeDirective } from 'vue';

import IntroSteppers from './IntroSteppers.vue';
import IntroActions from './IntroActions.vue';

export default Vue.extend({
  name: 'IntroCircle',
  components: {
    IntroActions,
    IntroSteppers,
  },
  props: {
    value: {
      type: Object as PropType<VNodeDirective>,
      required: true,
    },
    step: {
      type: Number,
      default: 0,
    },
    length: {
      type: Number,
      default: 0,
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    title(): string {
      return this.value.value.title as string;
    },
    text(): string {
      return this.value.value.text as string;
    },
    dataTestValue(): string {
      return this.value.value.dataTestValue || '' as string;
    },
    closeText(): string {
      return this.value.value.closeText as string;
    },
    withArrow(): boolean {
      return this.value.value.arrow as boolean;
    },
    introStyle(): string {
      const y = -315 + this.height / 2;
      const x = -315 + this.width / 2;
      return `transform: translate(${x}px, ${y}px)`;
    },
    solid(): boolean {
      return this.value.value.solid as boolean;
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 400);
  },
});
