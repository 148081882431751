import * as Names from '@/plugins/vue-router/routeNames';
import isAdmin from '@/plugins/vue-router/guards/middleware/isAdmin';

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName "[request]" */ '@/layouts/app/HolyGrailLayout.vue'),
    children: [
      {
        path: '',
        redirect: { name: Names.R_APP },
      },
      {
        path: 'errors/401',
        name: Names.R_ERROR_401,
        component: () => import(/* webpackChunkName "[request]" */ '@/views/errors/Error401.vue'),
      },
      {
        path: 'errors/403',
        name: Names.R_ERROR_403,
        component: () => import(/* webpackChunkName "[request]" */ '@/views/errors/Error403.vue'),
      },
      {
        path: 'errors/404',
        name: Names.R_ERROR_404,
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/errors/Error404.vue'),
        },
      },
      {
        path: 'errors/500',
        name: Names.R_ERROR_500,
        component: () => import(/* webpackChunkName "[request]" */ '@/views/errors/Error500.vue'),
      },
    ],
  },
  {
    path: '/app',
    name: Names.R_APP,
    component: () => import(/* webpackChunkName "[request]" */ '@/layouts/app/HolyGrailLayout.vue'),
    redirect: { name: Names.R_APP_PROFILE },
    children: [
      {
        path: 'me',
        name: Names.R_APP_ME,
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/app/Me.vue'),
        },
        redirect: { name: Names.R_APP_PROFILE },
      },

      {
        path: 'profile/:userId?',
        name: Names.R_APP_PROFILE,
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/app/Profile.vue'),
        },
      },

      {
        path: 'team/:teamId?',
        name: Names.R_APP_TEAM,
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/app/Team.vue'),
        },
      },
    ],
  },
  {
    path: '/app/orgstructure',
    component: () => import(/* webpackChunkName "[request]" */ '@/layouts/app/TreeLayout.vue'),
    redirect: { name: Names.R_APP_ORGSTRUCTURE_TREE },
    children: [
      {
        path: 'tree',
        name: Names.R_APP_ORGSTRUCTURE_TREE,
        props: {
          default: true,
        },
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/app/TeamTree.vue'),
        },
      },
    ],
  },
  {
    path: '/app/admin',
    name: Names.R_ADMIN,
    component: () => import(/* webpackChunkName "[request]" */ '@/layouts/app/HolyGrailLayout.vue'),
    redirect: { name: Names.R_ADMIN_USERS },
    meta: {
      middleware: [
        isAdmin,
      ],
    },
    children: [
      {
        path: 'users',
        name: Names.R_ADMIN_USERS,
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/admin/Users.vue'),
        },
      },
      {
        path: 'users/:userId/edit',
        name: Names.R_ADMIN_USERS_EDIT,
        props: {
          default: true,
        },
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/admin/UserEdit.vue'),
        },
      },
      {
        path: 'users/create',
        name: Names.R_ADMIN_USERS_CREATE,
        props: {
          default: true,
        },
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/admin/UserCreate.vue'),
        },
      },
      {
        path: 'orgstructure/:companyId/team/:teamId',
        name: Names.R_ADMIN_ORGSTRUCTURE_TEAM,
        props: {
          default: true,
        },
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/admin/OrgstructureTeam.vue'),
        },
      },
      {
        path: 'orgstructure/import',
        name: Names.R_ADMIN_ORGSTRUCTURE_IMPORT_INDEX,
        props: {
          default: true,
        },
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/admin/import/ImportIndex.vue'),
        },
      },
      {
        path: '/orgstructure/import/:importId/upload',
        name: Names.R_ADMIN_ORGSTRUCTURE_IMPORT_UPLOAD,
        props: {
          default: true,
        },
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/admin/import/ImportUpload.vue'),
        },
      },
      {
        path: 'orgstructure/import/:importId/processing',
        name: Names.R_ADMIN_ORGSTRUCTURE_IMPORT_PROCESSING,
        props: {
          default: true,
        },
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/admin/import/ImportProcessing.vue'),
        },
      },
      {
        path: 'licenses',
        name: Names.R_ADMIN_LICENSES,
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/admin/Licenses.vue'),
        },
      },
      {
        path: 'applications',
        name: Names.R_ADMIN_APPLICATIONS,
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/admin/Applications.vue'),
        },
      },
      {
        path: 'applications/create',
        name: Names.R_ADMIN_APPLICATIONS_CREATE,
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/admin/ApplicationsCreate.vue'),
        },
      },
      {
        path: 'applications/:appId',
        name: Names.R_ADMIN_APPLICATIONS_EDIT,
        props: {
          default: true,
        },
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/admin/ApplicationsEdit.vue'),
        },
      },
    ],
  },

  {
    path: '/profile/:query?',
    name: Names.R_PROFILE,
    props: true,
    component: () => import(/* webpackChunkName "[request]" */ '@/views/profile/ProfileLayout.vue'),
  },

  {
    path: '*',
    redirect: { name: Names.R_ERROR_404 },
  },
];

export default routes;
