import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import userUUIDGet from '@/helpers/userUUIDGet';
import { companyId } from '@/services/cookies';

if (process.env.VUE_APP_SENTRY_ENABLE) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    initialScope: {
      tags: { company_id: companyId.get() },
      user: { id: userUUIDGet() },
    },
    integrations: [
      new VueIntegration({
        Vue,
        tracing: true,
        attachProps: true,
        logErrors: true,
      }),
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1,
  });
}
