/*
 * Гвард для защиты админских маршрутов
 */
import * as Names from '@/plugins/vue-router/routeNames';

const isAdmin = ({ store }) => {
  if (!store.state.roles.find((e) => e.role === 'admin')) { return { name: Names.R_APP }; }
  return false;
};

export default isAdmin;
