import { accessToken } from '@/services/cookies';

const userUUIDGet = () => {
  const token = accessToken.get();
  if (token) {
    const [, payload] = token.split('.');
    if (payload) {
      const { sub: userId } = JSON.parse(atob(payload));
      return userId;
    }
  }
  return null;
};

export default userUUIDGet;
