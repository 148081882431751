import { DirectiveOptions } from 'vue';
import IntroDrop from '@/components/ui/Intro/IntroDrop.vue';
import IntroCircle from '@/components/ui/Intro/IntroCircle.vue';
import IntroRectangle from '@/components/ui/Intro/IntroRectangle.vue';

import { Intro } from './intro';

const intro = new Intro({
  components: {
    drop: IntroDrop, circle: IntroCircle, rectangle: IntroRectangle,
  },
});

export default {
  bind(el, binding) {
    intro.bind(el, binding);
  },
  unbind(el, binding) {
    intro.unbind(el, binding);
  },
} as DirectiveOptions;

// для отладки работы
// const plugin: PluginFunction<Vue> = (vue) => {
//   Object.defineProperty(vue.prototype, '$intro', {
//     get() {
//       return intro;
//     },
//   });
// };
//
// Vue.use(plugin);
